@import "~bootstrap";
@font-face {
  font-family: 'bangers';
  src: url('../assets/Bangers-Regular.ttf')  format('truetype');
}
@font-face {
  font-family: 'nunito';
  src: url('../assets/Nunito.ttf')  format('truetype');
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/login.jpg');
}

.wrap-login100 {
  width: 680px;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 30px 60px;
}
body{
  font-family: 'nunito',sans-serif;
}
.bangers{
  font-family: 'bangers',sans-serif;
}
.no-resize{
  resize: none;
}
.pointer{
  cursor: pointer;
}
.rounded-image{
  border-radius: 50%;
  object-fit: contain;
}
.w-900{
  width: 900px;
}
.full-page-table{
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.school-container{
  z-index: 1000;
  position: relative;
}
.kids-list{
  overflow: auto;
  max-height: 80vh;
}
.kids-list::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.kids-list::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
  background:white;
  border-radius: 3px;

}

.kids-list::-webkit-scrollbar-thumb {
  background-color:gainsboro;
  border-radius: 3px;
}
.no-select{
  user-select: none;
}
.truncate {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}